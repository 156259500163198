import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { IoMdGlobe } from '@react-icons/all-files/io/IoMdGlobe'
import UnstyledButton from 'components/button/unstyled-button'
import Content from 'components/content-full-width'

const SelectedRegionRibbon = ({ region, toggleModal, ...props }) => {
  return (
    <SelectedRegion {...props}>
      <SelectedRegionContent>
        <IoMdGlobe role='presentation' focusable='false' aria-hidden='true' />
        <span>
          Your selected region:{' '}
          <UnstyledButton
            className='region'
            onClick={() => toggleModal(true)}
            title='Edit selected region'
          >
            {region}
          </UnstyledButton>
        </span>
      </SelectedRegionContent>
    </SelectedRegion>
  )
}

SelectedRegionRibbon.propTypes = {
  region: PropTypes.oneOf(['north', 'south']),
  toggleModal: PropTypes.func.isRequired,
}

const SelectedRegion = styled.div`
  align-items: center;
  background-color: #fff;
  color: ${props => props.theme.color.n400};
  display: flex;
  font-weight: 600;
  flex-direction: row;
  font-size: 1em;
  line-height: 1.5;
  width: 100%;

  svg {
    color: ${props => props.theme.color.g400};
    font-size: 1.1em;
    margin-right: 8px;
    vertical-align: middle;
  }

  span {
    vertical-align: middle;
  }

  .region {
    color: ${props => props.theme.color.g400};
    display: inline-block;
    text-decoration: underline;
    text-transform: capitalize;

    :hover,
    :focus {
      color: ${props => props.theme.color.n400};
    }
  }
`

const SelectedRegionContent = styled(Content)`
  padding-top: 16px;
  padding-bottom: 16px;
`

export default SelectedRegionRibbon

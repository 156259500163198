import useCookie from 'react-use/lib/useCookie'
import { useToggle } from 'hooks/use-toggle'
import { regionCookieName } from './constants'

const useRegionPicker = () => {
  const [region, setRegion] = useCookie(regionCookieName)
  const [isOpen, toggleModal] = useToggle(!region)

  return {
    region,
    setRegion,
    isOpen,
    toggleModal,
  }
}

export default useRegionPicker

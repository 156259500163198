import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import styled from 'styled-components'
import UnstyledButton from 'components/button/unstyled-button'
import Button from 'components/button/button'
import Spacer from 'components/new/spacer'
import useMedia from 'react-use/lib/useMedia'
import noop from 'utils/noop'
import H3 from 'components/new/typography/h3'
import H4 from 'components/new/typography/h4'
import P from 'components/new/typography/p'

const NorthStates = () => {
  return (
    <svg
      viewBox='0 0 100 100'
      fill='currentColor'
      aria-hidden='true'
      focusable='false'
      role='presentation'
    >
      <path d='m30.5 21.4l0.9-0.8 1-0.2 2.5-1.5 1-0.1 0.2 0.2-2.5 2-1.5 0.7-0.9 0.3-0.7-0.6zm36.2 17.9l0.2 1.1 1.4 0.2 0.6-0.5s0-0.6-0.1-0.7-0.6-0.9-0.6-0.9h-1-0.7l-0.2 0.5 0.4 0.3zm10.3 29l-1-3.8-0.6-4.1-0.9-1.5-1-0.9-0.8 0.4-1.8 0.6-1.1 2.1-1.4 1.5-0.5 0.2-0.6-0.3s-1.1-0.8-1-1c0.1-0.3 0.4-2.2 0.4-2.2l1.5-0.4 0.5-1.4 0.4-1.1 1.1-0.6 0.3-4.4-0.6-1.1-0.5-0.4-0.3-1 0.4-0.3 0.7 0.2 0.1-0.7-1-1.1-0.4-1.2h-0.9l-1.9-0.8-2.2-1.7-1.2-0.1-0.3 0.3-0.4-0.3-1.2-1.1-1.4 0.6-1.4 0.9v1.6l0.4 0.2 0.9 0.3 0.2 0.4-1.2 0.2h-1.1l-0.7 0.7-0.2 0.9 0.1 0.7-0.1 2.4-1.7 0.8-0.3-0.1 0.2-1.8 0.7-1 0.4-1-0.3-0.4-0.9 0.3-0.6 1.8-1.3 0.4-0.9 0.8-0.1 0.4 0.2 0.4-0.4 1.1-1 0.1-0.1 0.2 0.2 1.1-0.8 2.6-0.9 1.7 0.1 2.1 0.2 0.5-0.5 1-0.2 0.3-0.3 1.2 1.3 2.8 1 3 0.4 2.2-0.6 2-0.7 2.5-1.3 2.1-0.3 1.2-1.6 1.2 1.9 0.1h9.5l3.2-0.1v0.7l3-0.2 4.6-0.2h1.7l0.1-0.3 0.1-0.6 1.1-1.5 1-0.7 0.1-2.2 0.8-0.6 0.5-0.1 0.3-1.5 0.8-1.3 0.4 0.3v0.3l0.3 0.1 0.9-0.3 0.5-4.1zm-54-34.9l0.3-0.2 1.2-0.2 1.7-0.8v-0.4l0.3-0.3 2.7-0.1 1.1-0.7 2-0.7 0.1-0.6 1-1.2 0.8-0.3 0.6-0.7 1.1-0.9 2-0.9h2.1l0.4 0.5-0.2 0.4-1.7 0.3-0.5 1.3-1 0.2-0.3 1-1.2 1.3-0.3 1.1 0.3 0.2 0.5-0.4 1.7-1.1 0.5 0.6 1 0.1 1.4 0.6 0.6 0.6 0.5 1.4 1.1 1.3 1.7 0.1 0.7-0.4 0.6 0.6 0.7 0.3 0.6-0.3 0.5 0.1 0.8-0.3 1.9-1.4 1.5-0.3 2.9 0.2 2.1-0.6 1.2-0.4 0.6 0.1-0.3 2.5 0.2 0.2 1.2 0.5 0.9-0.1 2.8-0.4 0.5-0.4 0.6 0.3-0.3 3 1.3 1.5 0.5 0.3 0.5 0.5-0.6 0.1-0.3-0.2-1.6-0.5-1 0.2-1-0.2-1.5 0.5-0.8-0.1-2.5-0.8-2.2-0.2-1 1h-3.1l-1.1 0.2-0.6 1.3-0.6 0.4-0.2-0.1-0.6-0.8-2.1 0.9h-0.3l-0.4-0.8h-0.4l-1 1.8-0.6 1.7-1.7 2.9-0.5-0.5-0.6-0.5-0.2-4.5-1.5-0.8-0.8-1.1-5.2-1.8-1.2-0.5-3.5-1.3-3.4-0.9-1.4-2.4z' />
    </svg>
  )
}

const SouthStates = () => {
  return (
    <svg
      viewBox='0 0 100 100'
      fill='currentColor'
      aria-hidden='true'
      focusable='false'
      role='presentation'
    >
      <path d='m71.9 71.2l-22.9-0.1-7 0.1h-2l-1.7-0.2-0.2 1.7-3.7-0.2h-3.1l-4.8-0.5-0.4 2.5-1.2 2.6-0.6 1.3-0.8 1.9-0.3 1.1-1.9 0.8 0.5 1.6-0.6 1.9-0.5 0.3 3.2 0.2 10.6 0.3 2.3 0.2 3.6 0.2 12.3 0.1 4.5 0.1h3.7l3.7-0.1 2.2-0.1 0.2-2 0.8-0.6 1.2-0.2 0.5-1.6 2-1 1.8-0.5 2-1.4 2-0.7 0.5-1.5 2.1-1.5h0.3s-0.1 0.5 0.3 0.5 0.8 0.2 0.8 0.2l1.2-1.4 0.9-0.2 1 0.2 0.9-1.5 1-0.9 0.3-0.4 0.3-1.7-0.6-0.2-1.3 0.8-3.5-0.3-5.3 0.3-4.3-0.1z' />
      <path d='m83.7 62.4l-1.1 1.1-2 1.4-2.1 2.4-0.9 0.7-0.1 0.9-1.8 0.7-2.6 1.2h-1.6l-22.9-0.2-7 0.1h-2l-1.7-0.2-0.3 1.8-3.5-0.3h-3.1l-4.6-0.4h0.8l1-0.7 1-0.4 0.2-1.4 0.5-0.8-0.6-1.2 0.4-0.8 1.1-0.7 0.9-0.2 1.1 0.7 1.5 0.7 0.5-0.1 0.2-1-0.5-1.1 0.2-1 0.9-0.5 1.4-0.1 0.7-0.2-0.2-0.8-0.2-0.9 0.5-0.3 0.6-1.4 1.4-0.6 2.6-0.2h1.6l0.5 0.9 0.7 0.4 0.9-1.3 1.3-0.5 0.8 0.8 0.3 0.5 0.9-0.1 0.1-1.5 1.3-0.6 0.5-0.3 0.4 0.8 2 0.2 0.4-0.9v-1l1.4-1.4 2.2-1.5 0.4-2h1.2l1.8-0.6 1.3-0.7 0.3-0.8-0.6-0.7 0.3-0.9 1.8 0.1 1.1-0.2 1.2 0.8 0.5 2 2.5 0.4 0.7 0.9 0.9 0.2 1.1-0.5 1.3 0.4 0.5 0.7 1.3-1 0.8-0.5 0.7 0.1 0.2 1.2 0.7 0.5 1 1.1-0.2 2.4 0.3 0.7 1.1 0.8 0.2 1 1.1 1.8 1 1.3 1.5 0.8z' />
      <path d='m38.4 59l0.2-1.2 0.4-2 1.1-1.2 1-1.6 1.3-1.7 0.1-2.6-0.7-1.3v-1.4l0.6-2.4 0.1-3.1 0.2-7.1 0.1-6.8 0.1-5.2 1.3 0.5 0.6 0.5 0.5-0.1 1-0.8 1.3-0.6 2.2 0.2h9.7 2.5l-0.1 7 0.2 16.3v2.5l-0.3 1 0.5 0.8v0.6l-1.2 0.6-1.6 0.5-1.4 0.1-0.5 2.5-2.2 1.2-1.4 1.6v1.1l-0.3 0.6-1.5-0.2-0.6-0.8-1.1 0.4-1.2 0.5-0.1 1.3-0.5 0.3-0.2-0.5-0.9-0.8-1.5 0.4-0.8 1.2-0.5-0.3-0.6-0.8h-2l-2.5 0.2-1.3 0.6z' />
      <path d='m38.1 59.3l0.2-1.6 0.3-2.1 1.2-1.3 0.9-1.6 1.3-1.6 0.1-2.3-0.7-1.6 0.1-1.5 0.5-2.3v-3.2l0.3-6.9 0.1-6.6 0.1-5.1-0.1-0.4-0.2-1.2-0.4-1.7-0.6-0.9-0.5-1.2 0.1-2.4-4.4 0.1-12-0.5-3.6-0.4v1l1 0.4 0.3 0.5 0.1 0.8 1.5 1.7 0.2 1-0.4 1.6-1 1.5-0.4 1.1-1.1 0.6-0.8 0.2-2.4 0.4-0.4 0.8-0.4 0.8 0.2 0.6 0.7 0.8-0.3 1.8-0.9 0.6-0.4 0.7-0.1 1.2-0.8 0.1-0.8 0.4-0.2 0.6v0.9l-0.8 0.5-0.6 1.2v1.6l0.7 3.3 2.8 3.6 2.2 1.8-0.3 1.9 0.3 0.6 2.8 0.5 1.1 0.7-0.5 1.6-0.9 2.6-0.4 1.4 0.8 1.8 2.6 2.6 2 0.5 0.7 2.3 0.8 1.5-0.7 1.3 0.5 1.9 0.7 1 0.9-0.3 0.4-0.9 1-0.5 1.5-0.3 1.3 0.7 1.2 0.6 0.4-0.1v-0.5l-0.3-1.3 0.3-1 1.1-0.6 1.1-0.3 0.5-0.1-0.2-0.6-0.2-1 0.6-0.5 0.3-1.3z' />
    </svg>
  )
}

const defaultStyle = {
  content: {
    backgroundColor: '#f8f8f9',
    border: 'none',
    borderRadius: 'none',
    bottom: 'auto',
    height: '95%',
    left: '50%',
    overflowY: 'scroll',
    padding: '36px 24px',
    position: 'absolute',
    right: 'auto',
    textAlign: 'center',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: '9999',
  },
}

const desktopStyle = {
  content: {
    ...defaultStyle.content,
    height: 'auto',
    maxHeight: '100vh',
    maxWidth: '600px',
    overflowY: 'auto',
  },
  overlay: {
    ...defaultStyle.overlay,
  },
}

ReactModal.setAppElement('#___gatsby')

const Grid = styled.div`
  @media (max-width: 479px) {
    > * {
      width: 100%;

      :first-child {
        margin-bottom: 24px;
      }
    }
  }

  @media (min-width: 480px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    > * {
      width: calc(50% - 12px);

      :first-child {
        margin-right: 24px;
      }
    }
  }
`

const RegionPickerModal = props => {
  const { isOpen, region, setRegion, toggleModal } = props

  const [selectedRegion, setSelectedRegion] = useState(region)

  const isDesktop = useMedia('(min-width: 600px)')

  const closeModal = () => {
    if (selectedRegion) {
      setRegion(selectedRegion)
      toggleModal(false)
    }
  }

  return (
    <ReactModal
      style={isDesktop ? desktopStyle : defaultStyle}
      isOpen={isOpen}
      onRequestClose={noop}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      <H3 as='h2' style={{ textAlign: 'center' }}>
        Please select your closest region
      </H3>
      <Spacer size='xs' />
      <P style={{ margin: '0 auto', maxWidth: '400px' }}>
        We'll use your selection to personalize your experience with local offers and availability.
      </P>
      <Spacer size='m' />
      <Grid>
        <Region
          icon={SouthStates}
          title='South'
          subtitle='IL, IN, KY, TN'
          onClick={() => setSelectedRegion('south')}
          className={selectedRegion === 'south' ? 'active' : null}
        />
        <Region
          icon={NorthStates}
          title='North'
          subtitle='MI'
          onClick={() => setSelectedRegion('north')}
          className={selectedRegion === 'north' ? 'active' : null}
        />
      </Grid>
      <Spacer size='m' />
      <Button
        color='green'
        ghost
        disabled={!selectedRegion}
        style={{ display: 'inline-block' }}
        onClick={closeModal}
      >
        Continue
      </Button>
    </ReactModal>
  )
}

RegionPickerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  region: PropTypes.oneOf(['north', 'south']),
  setRegion: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
}

const RegionContainer = styled(UnstyledButton)`
  background-color: #f8f8f9;
  border: 1px solid ${props => props.theme.color.n40};
  padding: 24px;
  position: relative;
  text-align: center;

  svg {
    color: ${props => props.theme.color.g100};
  }

  .title {
    color: ${props => props.theme.color.n200};
    text-align: center;
  }

  .subtitle {
    color: ${props => props.theme.color.n70};
    margin: 0;
  }

  .select-bubble {
    background-color: inherit;
    border: 1px solid ${props => props.theme.color.n50};
    border-radius: 50%;
    display: inline-block;
    height: 24px;
    position: absolute;
    right: 12px;
    top: 12px;
    vertical-align: middle;
    width: 24px;

    :after {
      border: solid #fff;
      border-width: 0 2px 2px 0;
      content: '';
      display: none;
      height: 12px;
      left: 8px;
      position: absolute;
      top: 4px;
      transform: rotate(45deg);
      width: 6px;
    }
  }

  &.active {
    background-color: #fff;

    svg {
      color: ${props => props.theme.color.g400};
    }

    .title {
      color: ${props => props.theme.color.n900};
    }

    .subtitle {
      color: ${props => props.theme.color.n400};
    }

    .select-bubble {
      background-color: ${props => props.theme.color.g400};
      border-color: ${props => props.theme.color.g400};

      :after {
        display: block;
      }
    }
  }
`

const Region = ({ icon: Icon, subtitle, title, ...props }) => {
  return (
    <RegionContainer {...props}>
      <Icon />
      <H4 as='h3' className='title'>
        {title}
      </H4>
      <Spacer size='xs' />
      <P className='subtitle'>{subtitle}</P>
      <div className='select-bubble' />
    </RegionContainer>
  )
}

export default RegionPickerModal
